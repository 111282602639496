import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import presseImage1 from '@images/presse/spendenscheckuebergabe.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentImageWrapper = tw.div`flex justify-center`
const ContentText = tw.div`col-span-12 xs:col-span-8`
const ImageSource = tw.small`text-white`

const PressePage = () => {
  const presseImageDl1 = '/download/brauhaus-spendenscheckuebergabe.jpg';

  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                Gelebte Bier- und Heimatliebe: ERDINGER Brauhaus setzt Spendentradition fort
              </Subtitle>
              <ContentText>
                <p>
                  <strong>
                    Spendenscheckübergabe an die Fischer’s Wohltätigkeitsstiftung
                  </strong>
                </p>
                <p>
                  Erding, 17. Juni 2024<br />
                  Bierbrauen und Erding – das ist gelebte Tradition. Seit jeher steht die
                  Privatbrauerei ERDINGER Weißbräu für die Liebe zum Bier und zur Heimat! Die Förderung
                  gemeinnütziger Initiativen in der Region Erding liegt der Brauerei daher besonders am Herzen. So fand
                  auch in diesem Jahr als symbolische Geste dieses Engagements wieder die traditionelle
                  Spendenscheckübergabe an die Fischer’s Wohltätigkeitsstiftung statt.
                </p>
                <p>
                  Zu einem erfreulichen Anlass lud ERDINGER Weißbräu in den Gasthof zur Post. Werner Brombach,
                  Inhaber der Privatbrauerei, sowie seine Geschäftsführer Josef Westermeier (Marketing und Vertrieb),
                  Dr. Stefan Kreisz (Vorsitzender) und  Dr. Stefan Huckemann (Finanzen/Controlling/IT und Organisation)
                  begrüßten die geladenen Gäste, unter ihnen Max Gotz, Oberbürgermeister der Stadt Erding, sowie
                  Vertreter der Fischer’s Wohltätigkeitsstiftung, unter anderem Landrat Martin Bayerstorfer
                  (Verwaltungsratsvorsitzender) und Matthias Vögele (Geschäftsführer). Denn im Namen der Brauerei
                  überreichte Josef Westermeier einen Scheck über 65.000 € an die Fischer’s Wohltätigkeitsstiftung. Die
                  Spendensumme stammt aus den Erlösen des Verkaufs von ERDINGER Brauhaus Helles.  „Es ist uns eine
                  Ehre, bis heute unseren Beitrag zum Stifterwillen von Katharina und Friedrich Fischer zu leisten“,
                  erläuterte Josef Westermeier und fügte hinzu: „Mit ERDINGER Brauhaus Helles stehen wir in der
                  großen Brautradition der Bierstadt Erding: Jeder Schluck unseres Hellen ist ein Genuss bester
                  bayerischer Brauhandwerkskunst und erfüllt gleichzeitig einen guten Zweck.“ Bei der
                  Spendenscheckübergabe verriet Landrat Martin Bayerstorfer, wie die 65.000 € eingesetzt werden:
                  „Wir freuen uns sehr über diese großzügige Spende. Damit wollen wir die Mitarbeiter- und Pflege
                  Ausstattung unseres Seniorenzentrums nochmals aufwerten. Neue Berufskleidung sowie wichtige
                  Hilfsmittel wie Hebezeuge werden die Pflege unserer Bewohner erleichtern.“
                </p>
                <br />
              </ContentText>

              <ContentImageWrapper>
                <div>
                  <a 
                      href={presseImageDl1}
                      target="_blank"
                    >
                      <img src={presseImage1} alt="Spendenscheckübergabe an die Fischer’s Wohltätigkeitsstiftung" />
                  </a>
                  <ImageSource>
                    Bildquelle: Wolfgang Krzizok<br /><br />
                    Mit dem Spendenscheck (v.l.): Harald Seisenberger (Gebietsverkaufsleiter ERDINGER Weißbräu), Dr. Stefan Huckemann (Geschäftsführer Finanzen/Controlling/IT und Organisation ERDINGER Weißbräu), Dr. Stefan Kreisz (Vorsitzender der Geschäftsführung ERDINGER Weißbräu), Landrat Martin Bayerstorfer, Oberbürgermeister Max Gotz, Werner Brombach (Inhaber ERDINGER Weißbräu), Michaela Heß-Sauer (Einrichtungsleitung Fischer’s Seniorenzentrum), Josef Westermeier (Geschäftsführer Marketing und Vertrieb ERDINGER Weißbräu), Matthias Vögele (Geschäftsführer der Fischer’s Stiftungsverwaltung), Andreas Brenninger (Gebietsverkaufsleiter ERDINGER Weißbräu)
                  </ImageSource>
                </div>
              </ContentImageWrapper>

            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
